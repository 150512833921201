import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Socials from "../components/about/socials";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/about.css";

import Tilt from "react-parallax-tilt";

import { Fade, Rotate } from "react-reveal";
const About = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "about");

	return (
		<>
			<Helmet>
				<title>{`About | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="about" />
				<div className="content-wrapper">
					<div className="about-logo-container">
						<div className="about-logo">
							<Tilt
								perspective={500}
								scale={1.02}
								trackOnWindow={true}
								tiltReverse={true}
							>
								<Logo width={46} />
							</Tilt>
						</div>
					</div>

					<Rotate top left duration={2000}>
						<div className="about-container">
							<div className="about-main">
								<div className="about-right-side">
									<Fade left duration={2000}>
										<div className="title about-title text-m-center">
											{INFO.about.title}
										</div>
									</Fade>
									<Fade left duration={2000}>
										<div className="subtitle about-subtitle text-m-center">
											{INFO.about.description}
										</div>
									</Fade>
									<div className="skils-title">
										My Skills :
									</div>
									<Fade bottom cascade duration={1200}>
										<div className="about-skills">
											{INFO.skills.programmingLanguages.map(
												(skill, index) => (
													<div
														className="about-skill"
														key={index}
													>
														<img
															src={skill.icon}
															alt={skill.name}
															className="about-skill-icon"
														/>
														<div className="about-skill-name">
															{skill.name}
														</div>
														<span className="name-bottom-arrow"></span>
													</div>
												)
											)}
										</div>
									</Fade>
								</div>

								<div className="about-left-side">
									<div
										className="about-image-container"
										style={{
											borderRadius: "10px",
										}}
									>
										<Tilt
											tiltMaxAngleX={10}
											tiltMaxAngleY={10}
											glareEnable={true}
											glarePosition="all"
											glareMaxOpacity={0.3}
											glareColor="#ffffff"
											style={{
												width: "100%",
												height: "100%",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
												borderRadius: "10px",
											}}
										>
											<Fade
												right
												duration={2000}
												style={{
													borderRadius: "40px",
												}}
											>
												<div
													className="about-image-wrapper card"
													style={{
														borderRadius: "1px",
													}}
												>
													<img
														src="https://i.hizliresim.com/qunrvq2.png"
														alt="about"
														className="about-image"
														style={{
															borderRadius:
																"10px",
														}}
													/>
												</div>
											</Fade>
										</Tilt>
									</div>

									<div className="about-socials">
										<Fade left duration={2000}>
											<Socials />
										</Fade>
									</div>
								</div>
							</div>
							<div className="about-socials-mobile">
								<Fade left duration={2000}>
									<Socials />
								</Fade>
							</div>
						</div>
					</Rotate>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</>
	);
};

export default About;
