const INFO = {
	main: {
		title: "Ahmet Kemal - Frontend Developer",
		name: "Ahmet Kemal Turker",
		email: "ahmetturker0541@gmail.com",
		logo: "https://avatars.githubusercontent.com/u/109578564?s=100&v=4",
	},

	socials: {
		twitter: "https://twitter.com/_ahmetkemal",
		github: "https://github.com/akt41",
		linkedin: "https://www.linkedin.com/in/ahmet-kemal-türker-205939223/",
		instagram: "https://instagram.com/ll.akt.ll",
	},

	skills: {
		programmingLanguages: [
			{
				name: "Javascript",
				icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg",
			},
			{
				name: "Next.js",
				icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nextjs/nextjs-original.svg",
			},
			{
				name: "React",
				icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
			},
			{
				name: "Node.js",
				icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original.svg",
			},
			{
				name: "HTML5",
				icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg",
			},
			{
				name: "CSS3",
				icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg",
			},
			{
				name: "Tailwind CSS",
				icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/tailwindcss/tailwindcss-plain.svg",
			},
			{
				name: "Bootstrap",
				icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-plain.svg",
			},
			{
				name: "Sass/Scss",
				icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/sass/sass-original.svg",
			},
			{
				name: "Git",
				icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original.svg",
			},
			{
				name: "Sanity.io",
				icon: "data:image/svg+xml,%3Csvg viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='28' height='28' rx='3' fill='%23F03E2F'%3E%3C/rect%3E%3Cpath d='M8.62 7.25c0 2.41 1.52 3.84 4.54 4.6l3.21.73c2.87.64 4.61 2.25 4.61 4.87a4.91 4.91 0 01-1.07 3.15c0-2.61-1.37-4.02-4.69-4.87l-3.15-.7c-2.52-.57-4.47-1.89-4.47-4.73a4.89 4.89 0 011.02-3.05z' fill='%23fff'%3E%3C/path%3E%3Cpath d='M17.94 16.8c1.37.87 1.97 2.07 1.97 3.8-1.13 1.42-3.12 2.22-5.46 2.22-3.94 0-6.7-1.9-7.3-5.21h3.78c.48 1.52 1.77 2.22 3.5 2.22 2.1 0 3.49-1.1 3.52-3.03' fill='%23F9B1AB'%3E%3C/path%3E%3Cpath d='M10.59 10.82a3.99 3.99 0 01-1.97-3.57c1.1-1.4 3-2.27 5.32-2.27 4 0 6.33 2.08 6.9 5H17.2c-.4-1.15-1.4-2.05-3.23-2.05-1.96 0-3.3 1.12-3.37 2.9' fill='%23F9B1AB'%3E%3C/path%3E%3C/svg%3E",
			},
			{
				name: "Firebase",
				icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/firebase/firebase-plain.svg",
			},
			{
				name: "MongoDB",
				icon: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original.svg",
			},
		],
	},

	homepage: {
		title: "Frontend Wizard & Mobile App Maestro 🚀",
		description:
			"As a frontend developer, I specialize in creating trustworthy and user-friendly websites. I thrive on solving challenges and constantly striving to learn new technologies. Building high-quality code that adheres to best practices and industry standards is my passion, and I am always seeking new opportunities to grow and excel in my craft.",
	},

	about: {
		title: "I’m Ahmet Kemal Turker I live in Istanbul, where I design the future.",
		description:
			"Hi, I am Ahmet Kemal, I have been developing myself in the field of Front-end for 2 years. I graduated from Istanbul Gelisim University Computer Programming Department. I am currently actively developing myself on React / Next.js.",
	},

	articles: {
		title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
	},

	projects: [
		{
			title: "Online Diet",
			description:
				"Online Diet is a freelance project offering personalized meal plans, nutrition counseling, and a blog with informative articles on health and nutrition. Users can also purchase recommended healthy foods.",
			logo: [
				{
					src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nextjs/nextjs-original.svg",
					title: "Next.js",
				},
				// {
				// 	src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
				// 	title: "React",
				// },
				{
					src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/tailwindcss/tailwindcss-plain.svg",
					title: "Tailwind CSS",
				},
				{
					src: "data:image/svg+xml,%3Csvg viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='28' height='28' rx='3' fill='%23F03E2F'%3E%3C/rect%3E%3Cpath d='M8.62 7.25c0 2.41 1.52 3.84 4.54 4.6l3.21.73c2.87.64 4.61 2.25 4.61 4.87a4.91 4.91 0 01-1.07 3.15c0-2.61-1.37-4.02-4.69-4.87l-3.15-.7c-2.52-.57-4.47-1.89-4.47-4.73a4.89 4.89 0 011.02-3.05z' fill='%23fff'%3E%3C/path%3E%3Cpath d='M17.94 16.8c1.37.87 1.97 2.07 1.97 3.8-1.13 1.42-3.12 2.22-5.46 2.22-3.94 0-6.7-1.9-7.3-5.21h3.78c.48 1.52 1.77 2.22 3.5 2.22 2.1 0 3.49-1.1 3.52-3.03' fill='%23F9B1AB'%3E%3C/path%3E%3Cpath d='M10.59 10.82a3.99 3.99 0 01-1.97-3.57c1.1-1.4 3-2.27 5.32-2.27 4 0 6.33 2.08 6.9 5H17.2c-.4-1.15-1.4-2.05-3.23-2.05-1.96 0-3.3 1.12-3.37 2.9' fill='%23F9B1AB'%3E%3C/path%3E%3C/svg%3E",
					title: "Sanity.io",
				},
			],
			linkText: "Github",
			link: "https://github.com/AKT41/Online-Diet",
		},

		{
			title: "Intercool",
			description:
				"Intercool is a freelance project. A website where users can review the products they want and then make an offer. Also an excellent admin panel made through sanity.io",
			logo: [
				{
					src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nextjs/nextjs-original.svg",
					title: "Next.js",
				},
				// {
				// 	src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
				// 	title: "React", or React Native
				// },
				{
					src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/tailwindcss/tailwindcss-plain.svg",
					title: "Tailwind CSS",
				},
				{
					src: "data:image/svg+xml,%3Csvg viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='28' height='28' rx='3' fill='%23F03E2F'%3E%3C/rect%3E%3Cpath d='M8.62 7.25c0 2.41 1.52 3.84 4.54 4.6l3.21.73c2.87.64 4.61 2.25 4.61 4.87a4.91 4.91 0 01-1.07 3.15c0-2.61-1.37-4.02-4.69-4.87l-3.15-.7c-2.52-.57-4.47-1.89-4.47-4.73a4.89 4.89 0 011.02-3.05z' fill='%23fff'%3E%3C/path%3E%3Cpath d='M17.94 16.8c1.37.87 1.97 2.07 1.97 3.8-1.13 1.42-3.12 2.22-5.46 2.22-3.94 0-6.7-1.9-7.3-5.21h3.78c.48 1.52 1.77 2.22 3.5 2.22 2.1 0 3.49-1.1 3.52-3.03' fill='%23F9B1AB'%3E%3C/path%3E%3Cpath d='M10.59 10.82a3.99 3.99 0 01-1.97-3.57c1.1-1.4 3-2.27 5.32-2.27 4 0 6.33 2.08 6.9 5H17.2c-.4-1.15-1.4-2.05-3.23-2.05-1.96 0-3.3 1.12-3.37 2.9' fill='%23F9B1AB'%3E%3C/path%3E%3C/svg%3E",
					title: "Sanity.io",
				},
			],
			linkText: "Github",
			link: "https://github.com/AKT41/Intercool",
		},
		{
			title: "Weather App",
			description:
				"When I first started learning React, I decided to create a Weather App project. The goal was to use the API provided by Open Weather and keep the searches local.",
			logo: [
				{
					src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
					title: "React",
				},
				{
					src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg",
					title: "CSS3",
				},
			],
			linkText: "Github",
			link: "https://github.com/AKT41/Weather-App",
		},
		{
			title: "Architect",
			description:
				"Architect is a freelance project. This project is a personal architect page. It is a useful website where he can showcase his work beautifully and share his writings and pictures on his blog page. ",
			logo: [
				{
					src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg",
					title: "HTML",
				},
				{
					src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg",
					title: "CSS3",
				},
			],
			linkText: "Github",
			link: "https://github.com/AKT41/ozkok-architect",
		},

		{
			title: "İnstagram Clone ( Mobile )",
			description:
				"In this project, I made a clone of the instagram mobile application with React Native, I added dark and light mode and it is one of my first React Native applications. ",
			logo: [
				{
					src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
					title: "React Native",
				},
			],
			linkText: "Github",
			link: "https://github.com/AKT41/instagram-clone-react-native",
		},
		{
			title: "Earthquake App ( Mobile )",
			description:
				"In this application, I made a mobile earthquake information application using the API written by my friend. You can instantly notify and review the latest earthquakes. (Data is taken instantly from Kandilli Observatory)",
			logo: [
				{
					src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
					title: "React Native",
				},
			],
			linkText: "Github",
			link: "https://github.com/AKT41/Deprem_App",
		},
		{
			title: "Personal Website",
			description:
				"This project is a personal website that you can customize for yourself. You can present your projects, introduce yourself beautifully and indicate your talents.( My personal website that I made for myself)",
			logo: [
				{
					src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg",
					title: "HTML",
				},
				{
					src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg",
					title: "CSS3",
				},
			],
			linkText: "Github",
			link: "https://github.com/AKT41/personal-website",
		},

		{
			title: "Movie App",
			description:
				"In this project, I used a fake movie JSON API that I prepared myself. I added features such as movie listing, filtering, search with the data from the API. I also used like and watched options and kept them locally and listed the liked and watched movies separately.",
			logo: [
				{
					src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
					title: "React",
				},
				{
					src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg",
					title: "CSS3",
				},
			],
			linkText: "Github",
			link: "https://github.com/AKT41/Movies",
		},
		{
			title: "Calculator App ( Mobile )",
			description:
				"This project is my first self-test project with React Native. I designed and built a classic mobile calculator and added dark and light mode.",
			logo: [
				{
					src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original.svg",
					title: "React Native",
				},
			],
			linkText: "Github",
			link: "https://github.com/AKT41/Calcutalor",
		},
		{
			title: "Shopping List",
			description:
				"This shopping list application was one of the first things I did when I first learned Javascript. When the listed products are received, they become crossed out. Products and their status are kept in cookies.",
			logo: [
				{
					src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original.svg",
					title: "HTML",
				},
				{
					src: "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original.svg",
					title: "CSS3",
				},
			],
			linkText: "Github",
			link: "https://github.com/AKT41/shopping-list",
		},
	],
};

export default INFO;
