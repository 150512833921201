import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Socials from "../components/about/socials";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/contact.css";

import { Rotate, Fade } from "react-reveal";
import Tilt from "react-parallax-tilt";

const Contact = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "contact");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Contact | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="contact" />
				<div className="content-wrapper">
					<div className="contact-logo-container">
						<div className="contact-logo">
							<Tilt
								perspective={500}
								scale={1.02}
								trackOnWindow={true}
								tiltReverse={true}
							>
								<Logo width={46} />
							</Tilt>
						</div>
					</div>
					<Rotate top left duration={2000}>
						<div className="contact-container">
							<div className="homepage-first-area">
								<div className="homepage-first-area-left-side">
									<div className="title contact-title contact-subtitle">
										<Fade left>
											Let's Get in Touch: Ways to Connect
											with Me
										</Fade>
									</div>

									<Fade left duration={2000}>
										<div className="subtitle contact-subtitle">
											Thank you for your interest in
											getting in touch with me. I welcome
											your feedback, questions, and
											suggestions. If you have a specific
											question or comment, please feel
											free to email me directly at &nbsp;{" "}
											<a
												href={`mailto:${INFO.main.email}`}
											>
												{INFO.main.email}
											</a>
											. I make an effort to respond to all
											messages within 24 hours, although
											it may take me longer during busy
											periods. Finally, if you prefer to
											connect on social media, you can
											find me on{" "}
											<a
												href={INFO.socials.instagram}
												target="_blank"
												rel="noreferrer"
											>
												{INFO.socials.instagram}
											</a>
											. Thanks again for your interest,
											and I look forward to hearing from
											you!
										</div>
									</Fade>
								</div>
								<div className="homepage-first-area-right-side">
									<Tilt
										tiltMaxAngleX={10}
										tiltMaxAngleY={10}
										glareEnable={true}
										glarePosition="all"
										glareMaxOpacity={0.3}
										glareColor="white"
										style={{
											borderRadius: "10px",
										}}
									>
										<div className="homepage-image-container">
											<Fade right duration={2000}>
												<div className="homepage-image-wrapper">
													<img
														src="https://i.hizliresim.com/kiydliy.jpg"
														alt="about"
														className="homepage-image"
													/>
												</div>
											</Fade>
										</div>
									</Tilt>
								</div>
							</div>
						</div>

						<div className="socials-container">
							<div className="contact-socials">
								<Fade left>
									<Socials />
								</Fade>
							</div>
						</div>
					</Rotate>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Contact;
