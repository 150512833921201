import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import AllProjects from "../components/projects/allProjects";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/projects.css";

import { Rotate, Fade } from "react-reveal";
import Tilt from "react-parallax-tilt";

const Projects = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "projects");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Projects | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="projects" />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Tilt
								perspective={500}
								scale={1.02}
								trackOnWindow={true}
								tiltReverse={true}
							>
								<Logo width={46} />
							</Tilt>
						</div>
					</div>
					<Rotate top left duration={1500}>
						<div className="projects-container">
							<div className="homepage-first-area">
								<div className="homepage-first-area-left-side">
									<Fade right duration={2000}>
										<div className="title projects-title ">
											The greatest impact you can make as
											an individual is to help others
											discover their potential.
										</div>
									</Fade>

									<Fade right duration={2000}>
										<div
											className="subtitle projects-subtitle"
											style={{
												width: "95%",
											}}
										>
											I've worked on a variety of projects
											over the years and I'm proud of the
											progress I've made. Many of these
											projects are open-source and
											available for others to explore and
											contribute to. If you're interested
											in any of the projects I've worked
											on, please feel free to check out
											the code and suggest any
											improvements or enhancements you
											might have in mind. Collaborating
											with others is a great way to learn
											and grow, and I'm always open to new
											ideas and feedback.
										</div>
									</Fade>
								</div>
								<div className="homepage-first-area-right-side">
									<Tilt
										tiltMaxAngleX={10}
										tiltMaxAngleY={10}
										glareEnable={true}
										glarePosition="all"
										glareMaxOpacity={0.3}
										glareColor="white"
										style={{
											borderRadius: "10px",
										}}
									>
										<div className="homepage-image-container">
											<Fade right duration={2000}>
												<div className="homepage-image-wrapper">
													<img
														src="https://img.freepik.com/free-photo/cozy-workplace-with-notebooks-beverage-desk-lamp-different-notes-wall-near-reminding-what-writing-daily-tasks-students-desktop-with-necessary-supplies_273609-32951.jpg?size=626&ext=jpg"
														alt="about"
														className="homepage-image"
														style={{
															objectPosition:
																"bottom",
														}}
													/>
												</div>
											</Fade>
										</div>
									</Tilt>
								</div>
							</div>
							<div className="projects-list">
								<AllProjects />
							</div>
						</div>
					</Rotate>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Projects;
