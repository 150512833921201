import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./styles/navBar.css";

const NavBar = (props) => {
	const { active } = props;
	const [toggleNav, setToggleNav] = useState(false);

	const handleToggleNav = () => {
		setToggleNav(!toggleNav);
	};
	return (
		<r>
			<div className="nav-container">
				<div className="toggle-nav" onClick={handleToggleNav}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className={`icon icon-tabler icon-tabler-chevron-right ${
							toggleNav ? "rotate" : ""
						}`}
						width="24"
						height="24"
						viewBox="0 0 24 24"
						strokeWidth="2"
						stroke="currentColor"
						fill="none"
						strokeLinecap="round"
						strokeLinejoin="round"
					>
						<path
							stroke="none"
							d="M0 0h24v24H0z"
							fill="none"
						></path>
						<path d="M9 6l6 6l-6 6"></path>
					</svg>
				</div>
				<nav className={`navbar ${toggleNav ? "navbar-open" : ""}`}>
					<div className="nav-background">
						<ul className="nav-list">
							<li
								className={
									active === "home"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/">Home</Link>
							</li>
							<li
								className={
									active === "about"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/about">About</Link>
							</li>
							<li
								className={
									active === "projects"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/projects">Projects</Link>
							</li>
							<li
								className={
									active === "contact"
										? "nav-item active"
										: "nav-item"
								}
							>
								<Link to="/contact">Contact</Link>
							</li>
						</ul>
					</div>
				</nav>
			</div>
		</r>
	);
};

export default NavBar;
