import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faTwitter,
	faGithub,
	faStackOverflow,
	faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import Logo from "../components/common/logo";
import Footer from "../components/common/footer";
import NavBar from "../components/common/navBar";
import Works from "../components/homepage/works";
import AllProjects from "../components/projects/allProjects";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/homepage.css";

import Tilt from "react-parallax-tilt";

import { Fade, Rotate } from "react-reveal";

const Homepage = () => {
	const [stayLogo, setStayLogo] = useState(false);
	const [logoSize, setLogoSize] = useState(80);
	const [oldLogoSize, setOldLogoSize] = useState(80);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		const handleScroll = () => {
			let scroll = Math.round(window.pageYOffset, 2);

			let newLogoSize = 80 - (scroll * 4) / 10;

			if (newLogoSize < oldLogoSize) {
				if (newLogoSize > 40) {
					setLogoSize(newLogoSize);
					setOldLogoSize(newLogoSize);
					setStayLogo(false);
				} else {
					setStayLogo(true);
				}
			} else {
				setLogoSize(newLogoSize);
				setStayLogo(false);
			}
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [logoSize, oldLogoSize]);

	const currentSEO = SEO.find((item) => item.page === "home");

	const logoStyle = {
		display: "flex",
		position: stayLogo ? "fixed" : "relative",
		top: stayLogo ? "3vh" : "auto",
		zIndex: 999,
		border: stayLogo ? "none" : "none",
		borderRadius: stayLogo ? "50%" : "none",
		boxShadow: stayLogo ? "none" : "none",
	};

	

	return (
		<React.Fragment>
			<Helmet>
				<title>{INFO.main.title}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="home" />
				<div className="content-wrapper">
					<div className="homepage-logo-container">
						<div style={logoStyle} className="mobile-z-index">
							<Tilt
								tiltMaxAngleX={25}
								tiltMaxAngleY={25}
								perspective={500}
								scale={1.02}
								trackOnWindow={true}
								tiltReverse={true}
							>
								<Logo width={logoSize} link={false} />
							</Tilt>
						</div>
					</div>
					<Rotate top left duration={2000}>
						<div className="homepage-container">
							<div className="homepage-first-area">
								<Fade left cascade>
									<div className="homepage-first-area-left-side">
										<div className="title homepage-title">
											{INFO.homepage.title}
										</div>

										<div className="subtitle homepage-subtitle">
											{INFO.homepage.description}
										</div>
									</div>
								</Fade>

								<div
									className="homepage-first-area-right-side"
									style={{
										borderRadius: "10px",
									}}
								>
									<Tilt
										tiltMaxAngleX={10}
										tiltMaxAngleY={10}
										glareEnable={true}
										glarePosition="all"
										glareMaxOpacity={0.3}
										glareColor="white"
										style={{
											borderRadius: "10px",
										}}
									>
										<div
											className="homepage-image-container"
											style={{
												borderRadius: "10px",
											}}
										>
											<Fade
												right
												duration={2000}
												style={{
													borderRadius: "10px",
												}}
											>
												<div
													className="homepage-image-wrapper"
													style={{
														borderRadius: "10px",
													}}
												>
													<img
														src="https://i.hizliresim.com/t4xvedt.jpg"
														alt="about"
														className="homepage-image"
														style={{
															borderRadius:
																"10px",
														}}
													/>
												</div>
											</Fade>
										</div>
									</Tilt>
								</div>
							</div>
						</div>

						<div className="homepage-socials">
							<Fade bottom cascade duration={2000}>
								<a
									href={INFO.socials.twitter}
									target="_blank"
									rel="noreferrer"
								>
									<FontAwesomeIcon
										icon={faTwitter}
										className="homepage-social-icon"
									/>
								</a>
								<a
									href={INFO.socials.github}
									target="_blank"
									rel="noreferrer"
								>
									<FontAwesomeIcon
										icon={faGithub}
										className="homepage-social-icon"
									/>
								</a>
								<a
									href={INFO.socials.stackoverflow}
									target="_blank"
									rel="noreferrer"
								>
									<FontAwesomeIcon
										icon={faStackOverflow}
										className="homepage-social-icon"
									/>
								</a>
								<a
									href={INFO.socials.instagram}
									target="_blank"
									rel="noreferrer"
								>
									<FontAwesomeIcon
										icon={faInstagram}
										className="homepage-social-icon"
									/>
								</a>
								<a
									href={`mailto:${INFO.main.email}`}
									target="_blank"
									rel="noreferrer"
								>
									<FontAwesomeIcon
										icon={faMailBulk}
										className="homepage-social-icon"
									/>
								</a>
							</Fade>
						</div>

						<div className="homepage-projects">
							<AllProjects />
						</div>

						<div className="homepage-after-title">
							<div className="homepage-works">
								<Fade bottom>
									<Works />
								</Fade>
							</div>
						</div>
					</Rotate>

					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Homepage;
