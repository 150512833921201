import React from "react";

import Project from "./project";

import INFO from "../../data/user";

import "./styles/allProjects.css";

import Tilt from "react-parallax-tilt";

const AllProjects = () => {
	return (
		<div className="all-projects-container">
			{INFO.projects.map((project, index) => (
				<div className="all-projects-project" key={index}>
					<Tilt tiltMaxAngleX={8} tiltMaxAngleY={2}>
						<Project
							logo={project.logo}
							title={project.title}
							description={project.description}
							linkText={project.linkText}
							link={project.link}
						/>
					</Tilt>
				</div>
			))}
		</div>
	);
};

export default AllProjects;
